






import {Component} from 'vue-property-decorator';
import {ROUTE_USER_INFORMATION} from '@/router/routes';
import Vue from 'vue';

@Component({
  components: {
  }
})
export default class HomeView extends Vue {
  /**
   * redirects to the user information view for now
   */
  async created() {
    // navigates to the user information view if he isn't already on the page
    if(this.$route.name != ROUTE_USER_INFORMATION) {
      await this.$router.push({name: ROUTE_USER_INFORMATION});
    }
  }
}
